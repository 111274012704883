if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';

$(document).ready(function(){

  $('.services-carousel').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  });

  $('.show-overlay').click(function(){
    $('.overlay').addClass('show');
    $('.overlay iframe')[0].contentWindow.focus();
  });

  $('.overlay-container, .close, .close hr').click(function(){
    $('.overlay').removeClass('show');
  });

  $('.overlay *').click(function(){
    event.stopPropagation();
  });

  var threeItemWaypoint = new Waypoint({
    element: document.querySelector('.threeitem-section'),
    handler: function(direction) {
      if(direction == 'down'){
        $('.mobile-slide-up').addClass('show');
      } else {
        $('.mobile-slide-up').removeClass('show');
      }
    },
    offset: '50%',
  });

  var sectionWaypoints = [];
  $.each( $('section'), function(index,value){
    sectionWaypoints.push(new Waypoint({
      element: value,
      handler: function(direction) {
        if(direction == 'down'){
          $(value).addClass('trigger');
        }
      },
      offset: windowOffset
    }));
  })
})
